import React, {useState, useEffect} from 'react';
import {GOLD_COLOR, METAL_COLOR, POP_SQUARE_SIZE, SCIENCE_COLOR} from './App'
import tinycolor from "tinycolor2";
import * as PropTypes from "prop-types";

/*
{
          "id": 1,
          "human": false
        },
        "species": {
          "type": "Terran"
        },
        "populationWheels": {
          "GOLD": {
            "resourceType": "GOLD",
            "onTrack": 9,
            "graveyard": 0,
            "WHEEL_VALUES": [
              2, ...
            ]
          },
          "METAL": {
            "resourceType": "METAL",
            "onTrack": 11,
            "graveyard": 0,
            "WHEEL_VALUES": [
              2, ...
            ]
          },
          "SCIENCE": {
            "resourceType": "SCIENCE",
            "onTrack": 9,
            "graveyard": 0,
            "WHEEL_VALUES": [
              2, ...
            ]
          }
        },
        "populationShips": 2,
        "bank": {
          "GOLD": 0,
          "METAL": 0,
          "SCIENCE": 0
        },
        "shipTech": {
          "INTERCEPTOR": {
            "type": "INTERCEPTOR",
            "tech": [
              "ION_CANNON",
              "DEFAULT_SOURCE",
              "DEFAULT_DRIVE"
            ]
          },
          "CRUISER": {
            "type": "CRUISER",
            "tech": [
              "ION_CANNON",
              "DEFAULT_SOURCE",
              "DEFAULT_DRIVE",
              "DEFAULT_HULL",
              "DEFAULT_COMPUTER"
            ]
          },
          "DREADNOUGHT": {
            "type": "DREADNOUGHT",
            "tech": [
              "ION_CANNON",
              "ION_CANNON",
              "DEFAULT_SOURCE",
              "DEFAULT_DRIVE",
              "DEFAULT_HULL",
              "DEFAULT_HULL",
              "DEFAULT_COMPUTER"
            ]
          },
          "STARBASE": {
            "type": "STARBASE",
            "tech": [
              "ION_CANNON",
              "DEFAULT_HULL",
              "DEFAULT_HULL",
              "DEFAULT_COMPUTER"
            ]
          }
        },
        "influenceDiscs": {
          "availableForUse": 5,
          "DISC_COST": [
            0, ...
          ]
        },
        "techRows": {
          "GRID": {
            "grid": "GRID",
            "researched": [],
            "DISCOUNTS": [
              0, ...
            ],
            "VPS": [
              0, ...
            ]
          },
          "NANO": {
            "grid": "NANO",
            "researched": [],
            "DISCOUNTS": [
              0, ...
            ],
            "VPS": [
              0, ...
            ]
          },
          "MILITARY": {
            "grid": "MILITARY",
            "researched": [],
            "DISCOUNTS": [
              0, ...
            ],
            "VPS": [
              0, ...
            ]
          }
        },
        "discoveryTokens": [],
        "reputation": []
      }
 */
/**
 * Will draw this on the 200w x 100h rectangle
 * @returns {*[]}
 * @constructor
 */
export function PlayerBoard({playerColor, playerState, firstPasser, isTurn}) {
  let res = []
  let pColor = tinycolor(playerColor)
  let bColor = pColor.darken(15)

  // Draw on the bottom left of the screen
  res.push(<rect stroke="#000" strokeWidth={1} fill={bColor}
                 x="0" y="0" width={200}
                 height={100}
                 rx="2"/>)

  let bank;
  if (playerState.bank) {
    bank = playerState.bank
  } else {
    bank = {}
  }

  let width = 50
  let radius = 11
  let gap = (width - 4 * radius) / 3
  let resourceSquare = (radius * 2 - 2 * gap) / 3
  let g = <g transform={"translate(0 30)"}>
    <Wheel wheel={playerState.populationWheels.GOLD} radius={radius}
           center={{x: gap + radius, y: gap + radius}}/>
    <Wheel wheel={playerState.populationWheels.METAL} radius={radius}
           center={{x: 2 * gap + 3 * radius, y: gap + radius}}/>
    <Wheel wheel={playerState.populationWheels.SCIENCE} radius={radius}
           center={{x: gap + radius, y: 2 * gap + 3 * radius}}/>
    <rect stroke="#000" strokeWidth={0.25}
          x={3 * radius + 2 * gap - resourceSquare / 2} y={2 * gap + 2 * radius}
          width={resourceSquare}
          height={resourceSquare} fill={GOLD_COLOR}></rect>
    res.push(
    <text fontSize={resourceSquare / 2} x={3 * radius + 2 * gap}
          y={2 * gap + 2 * radius + resourceSquare / 2}
          dominantBaseline="middle" textAnchor="middle">{bank.GOLD}</text>
    )
    <rect stroke="#000" strokeWidth={0.25}
          x={3 * radius + 2 * gap - resourceSquare / 2}
          y={2 * gap + 2 * radius + gap + resourceSquare}
          width={resourceSquare}
          height={resourceSquare} fill={METAL_COLOR}></rect>
    res.push(
    <text fontSize={resourceSquare / 2} x={3 * radius + 2 * gap}
          y={2 * gap + 2 * radius + gap + resourceSquare + resourceSquare / 2}
          dominantBaseline="middle" textAnchor="middle">{bank.METAL}</text>
    )
    <rect stroke="#000" strokeWidth={0.25}
          x={3 * radius + 2 * gap - resourceSquare / 2}
          y={2 * gap + 2 * radius + 2 * (gap + resourceSquare)}
          width={resourceSquare}
          height={resourceSquare} fill={SCIENCE_COLOR}></rect>
    res.push(
    <text fontSize={resourceSquare / 2} x={3 * radius + 2 * gap}
          y={2 * gap + 2 * radius + 2 * (gap + resourceSquare) + resourceSquare
              / 2}
          dominantBaseline="middle" textAnchor="middle">{bank.SCIENCE}</text>
    )
  </g>
  res.push(g)

  let g2 = <g transform={"translate(50 85)"}>
    <InfluenceRow color={playerColor}
                  influenceRow={playerState.influenceDiscs}/>
  </g>
  res.push(g2)

  let g3 = <g transform={"translate(50 -15)"}>
    <PopulationShips available={playerState.populationShipsAvailable}/>
  </g>
  res.push(g3)

  if (isTurn) {
    res.push(<text x={0} y={-7.5} fontSize={12}  dominantBaseline="middle"
                   textAnchor="start" >TURN</text>)
  }
  if (playerState.hasPassed) {
    if(firstPasser && playerState.player.id === firstPasser.id) {
      res.push(<text x={200} y={-17} fontSize={7.5} dominantBaseline="middle"
                     textAnchor="end" >FIRST</text>)
    }

    let text;
    if(playerState.isBankrupt) {
      text = "BANKRUPT"
    } else {
      text = "PASSED"
    }
    res.push(<text x={200} y={-7.5} fontSize={7.5} dominantBaseline="middle"
                   textAnchor="end" >{text}</text>)
  }

  return res
}

function PopulationShips({available}) {
  let ry = 6
  let rx = 10
  let gap = 3

  let res = []
  for (let i = 0; i < available; i++) {
    let leftX = (2 * i + 1) * rx + gap * (i + 1)
    res.push(<ellipse rx={rx} ry={ry} cx={leftX} cy={7.5} strokeWidth={0.25}
                      fill={"#aaa"} stroke={"#000"}/>)
  }
  return res
}

/**
 *
 *     "influenceDiscs": {
 *       "DISC_COST": [
 *         0, ...
 *       ],
 *       "availableForUse": 9
 *     },
 *
 * @param playerColor
 * @param influenceRow
 * @constructor
 */
function InfluenceRow({color, influenceRow}) {
  // Graphic is 150w x 15h. Discs should be 10 diameter
  let discRadius = 5
  let numDiscs = influenceRow.DISC_COST.length
  let gap = (150 - (numDiscs * discRadius * 2)) / (numDiscs + 1)
  let usedDiscs = numDiscs - influenceRow.availableForUse

  let res = []

  res.push(<rect width={150} height={15} strokeWidth={0.25} stroke={"#000"}
                 fillOpacity={0}/>)

  for (let i = 0; i < numDiscs; i++) {
    let available = i >= usedDiscs
    let valueAtTrack = influenceRow.DISC_COST[i] + ""
    let leftX = (2 * i + 1) * discRadius + gap * (i + 1)

    res.push(<circle cy={7.5} cx={leftX} r={discRadius} stroke={"#000"}
                     strokeWidth={0.25} fill={"#aaa"}/>)
    if (available) {
      res.push(<circle cy={7.5} cx={leftX} r={discRadius * 0.7} stroke={"#000"}
                       strokeWidth={0.25} fill={color}
                       fillOpacity={0.7}/>)
    }
    res.push(<text fontSize={discRadius} x={leftX}
                   y={7.5}
                   dominantBaseline="middle"
                   textAnchor="middle">{valueAtTrack}</text>)
  }
  return res;
}

/**
 * {
 *         "resourceType": "GOLD",
 *         "WHEEL_VALUES": [
 *           2, ...
 *         ],
 *         "onTrack": 10
 *       }
 * @param wheel
 * @constructor
 */
function Wheel({wheel, radius, center}) {
  let color;
  if (wheel.resourceType == "GOLD") {
    color = GOLD_COLOR
  } else if (wheel.resourceType == "SCIENCE") {
    color = SCIENCE_COLOR
  } else {
    color = METAL_COLOR
  }

  let res = []

  res.push(<circle strokeWidth={0.25} stroke={"#000"} fill={"#666"} r={radius}
                   cx={center.x} cy={center.y}/>)

  // Create wheel.WHEEL_VALUES number of pop squares, starting from -75deg up to 255deg
  let minRangeDeg = -60
  let maxRangeDeg = 240
  let degSpacing = (maxRangeDeg - minRangeDeg) / (wheel.WHEEL_VALUES.length - 1)
  let squareSize = POP_SQUARE_SIZE * 3 / 2
  let inlayRadius = radius - squareSize * 2 / 3
  for (let i = 0; i < wheel.WHEEL_VALUES.length; i++) {
    let used = i >= wheel.onTrack
    let valAtTrack = wheel.WHEEL_VALUES[wheel.WHEEL_VALUES.length - 1 - i]
    let deg = minRangeDeg + degSpacing * i
    let degAsRad = deg / 180 * Math.PI
    let popCenter = {
      x: center.x + Math.cos(degAsRad) * inlayRadius - squareSize / 2,
      y: center.y - Math.sin(degAsRad) * inlayRadius - squareSize / 2
    }
    let c = used ? "#aaa" : color

    res.push(<rect width={squareSize} height={squareSize} x={popCenter.x}
                   y={popCenter.y} fill={c}/>)
    res.push(<text fontSize={squareSize * 2 / 3}
                   x={popCenter.x + squareSize / 2}
                   y={popCenter.y + squareSize / 2}
                   dominantBaseline="middle"
                   textAnchor="middle">{valAtTrack}</text>)
  }

  return res
}