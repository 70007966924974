import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import * as PropTypes from "prop-types";
import {PlayerBoard} from './PlayerBoard'

const PLAYER_COLORS = [
  "#173b97",
  "#E66912",
  "#9E3A14",
  "#016367",
  "#A3C14A",
  "#6E3562"
]

const HEX_SIZE = 10.0 // The radius of the hex (to edge)
export const HEX_HEIGHT = HEX_SIZE
export const HEX_WIDTH = HEX_SIZE * Math.cos(30 / 180.0 * Math.PI)
export const HEX_SIDE = 2 * HEX_WIDTH * Math.tan(30 / 180.0 * Math.PI)
export const HEX_PEAK = HEX_HEIGHT - HEX_SIDE / 2
export const INFLUENCE_DISC_RADIUS = HEX_SIZE / 5
export const GOLD_COLOR = "gold"
export const SCIENCE_COLOR = "pink"
export const METAL_COLOR = "brown"

export const POP_SQUARE_SIZE = HEX_SIZE / 5 // 2

const UPDATE_FREQUENCY = 500; // in milliseconds
const GAME_MOVE_FREQUENCY = 300; // in milliseconds

function Header() {
  const [gameSummary, setGameSummary] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(
          "https://ccoie5bhgchxoynjhyq7sbw4bm0qycmv.lambda-url.us-east-1.on.aws/")
      .then(res => res.text())
      .then(
          (result) => {
            console.log("Result of summary call is ", result)
            setGameSummary(result)
          },
          (error) => {
            if (error) {
              setGameSummary(error)
            }
          }
      )
    }, UPDATE_FREQUENCY)
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    const interval =
        setInterval(() => {
          fetch(
              "https://wx7fdkgtwpj2pgovhk3jhxz57q0lzfxp.lambda-url.us-east-1.on.aws/")
          .then(res => res.text())
          .then(
              (result) => {
                console.log("Result of game move call is ", result)
              },
              (error) => {
                console.log("ERROR Result of game move call is ", error)
              }
          )
        }, GAME_MOVE_FREQUENCY)
    return () => clearInterval(interval)
  }, []);

  if (typeof gameSummary == 'string') {
    return (
        <div className="Header">
          {gameSummary}
        </div>
    );
  } else {
    return <div className="Header">Loading GameSummary (or error)</div>
  }
}

/**
 * {
 *   "gameState": {
 *     "boardState": {
 *       "unexploredHexes": [
 *         {
 *           "ring": 3,
 *           "points": 2,
 *           "artifactKey": false,
 *           "populationSquares": {
 *             "GOLD": 2
 *           },
 *           "hasInitialDiscoveryTile": true,
 *           "warpPortal": true,
 *           "wormhole": [
 *             "E",
 *             "SE"
 *           ],
 *           "initialAlienShips": []
 *         },
 *          ...
 *       ],
 *       "discoveryTiles": [
 *         {}
 *       ],
 *       "board": {
 *         "(2,-2,0)": {
 *           "definition": {
 *             "ring": 2,
 *             "points": 3,
 *             "artifactKey": true,
 *             "populationSquares": {
 *               "GOLD": 1
 *             },
 *             "hasInitialDiscoveryTile": false,
 *             "warpPortal": false,
 *             "wormhole": [
 *               "E",
 *               "W"
 *             ],
 *             "initialAlienShips": []
 *           },
 *           "rotation": 0,
 *           "coordinate": "(2,-2,0)",
 *           "controller": {
 *             "id": 1,
 *             "human": false
 *           },
 *           "occupiedPopulation": {
 *             "GOLD": 1
 *           },
 *           "ships": [
 *             {
 *               "type": "PlayerShip",
 *               "owner": {
 *                 "id": 1,
 *                 "human": false
 *               },
 *               "shipType": "INTERCEPTOR"
 *             }
 *           ]
 *         },
 *         ...
 *       ]
 *     },
 *     "numPlayers": 6,
 *     "techBag": [],
 *     "techTray": {
 *       "tech": {
 *         "GAUSS_SHIELD": 3
 *       }
 *     },
 *     "playerState": [
 *       {
 *         "player": {
 *           "id": 1,
 *           "human": false
 *         },
 *         "species": {
 *           "type": "Terran"
 *         },
 *         "hasPassed": true,
 *         "populationWheels": {
 *           "GOLD": {
 *             "resourceType": "GOLD",
 *             "onTrack": 10,
 *             "graveyard": 0,
 *             "WHEEL_VALUES": [
 *               2, 3, 4, ...
 *             ]
 *           },
 *           ...
 *         },
 *         "populationShipsAvailable": 3,
 *         "bank": {
 *           "GOLD": 0,
 *           "METAL": 0,
 *           "SCIENCE": 0
 *         },
 *         "shipTech": {
 *           "INTERCEPTOR": {
 *             "type": "INTERCEPTOR",
 *             "tech": [
 *               "ION_CANNON",
 *               "DEFAULT_SOURCE",
 *               "DEFAULT_DRIVE"
 *             ]
 *           },
 *           ...
 *         },
 *         "influenceDiscs": {
 *           "availableForUse": 10,
 *           "DISC_COST": [
 *              0,0, 1, 2, ...
 *           ]
 *         },
 *         "techRows": {
 *           "GRID": {
 *             "grid": "GRID",
 *             "researched": [],
 *             "DISCOUNTS": [
 *               0, 1, 2, ...
 *             ],
 *             "VPS": [
 *               0, ...
 *             ]
 *           },
 *           ...
 *           }
 *         },
 *         "discoveryTokens": [],
 *         "reputation": []
 *       },
 *       ...
 *     ],
 *     "currentTurn": 1,
 *     "sequentialPasses": 6
 *   },
 *   "gameLogic": {
 *     "npcBlueprints": {
 *       "ANCIENT": {
 *         "type": "ANCIENT"
 *       },
 *       "GUARDIAN": {
 *         "type": "GUARDIAN"
 *       },
 *       "GCDS": {
 *         "type": "GCDS"
 *       }
 *     }
 *   },
 *   "players": [
 *     {
 *       "id": 1,
 *       "human": false
 *     },
 *     ...
 *   ],
 *   "stateMachineStateClass": "net.phand.esd.statemachine.state.impl.CombatResolution",
 *   "actionContext": {
 *     "type": "net.phand.esd.statemachine.state.context.EmptyContext"
 *   }
 * }
 * @returns {JSX.Element}
 * @constructor
 */
function Table() {
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [game, setGame] = useState(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [zoom, setZoom] = useState(30 * HEX_WIDTH)
  const [dragPosition, setDragPosition] = useState(null)
  const [center, setCenter] = useState({x: 0, y: 0})

  // Initial Load from Lambda
  useEffect(() => {
    let interval = setInterval(() => {
      fetch(
          "https://zcevqflgcvhgj5fnh3bnrlm2fi0cgzji.lambda-url.us-east-1.on.aws/")
      .then(res => res.json())
      .then(
          (result) => {
            setLoaded(true)
            setGame(result)
          },
          (error) => {
            setLoaded(true)
            setError(error)
          }
      )
    }, UPDATE_FREQUENCY)
    return () => clearInterval(interval)
  }, []);

  // Set dimensions on resize
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
      console.log("Set dimensions to be " + window.innerWidth + "x"
          + window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    // Compute proper aspect ratio. The goal is to show at least 20 hexes wide and 20 hexes tall
    let aspectRatio = dimensions.width / dimensions.height
    let viewBoxWidth, viewBoxHeight;
    if (aspectRatio <= 1) {
      // We're taller than we are wide
      viewBoxWidth = zoom
      viewBoxHeight = viewBoxWidth / aspectRatio
    } else {
      // We're wider than we are tall
      viewBoxHeight = zoom
      viewBoxWidth = viewBoxHeight * aspectRatio
    }
    let topX = center.x - (viewBoxWidth / 2)
    let topY = center.y - (viewBoxHeight / 2)

    let hexes;
    if (game.gameState.boardState.board) {
      hexes = Object.values(game.gameState.boardState.board).map(
          hex => (<Hex hex={hex} players={game.players}/>))
    } else {
      hexes = <></>
    }

    let playerBoards = []
    if (game.gameState.playerState) {
      let degGapBetweenPlayers = 360 / game.gameState.playerState.length
      for (let i = 0; i < game.gameState.playerState.length; i++) {
        let boardWidth = HEX_WIDTH * 7 * 2; // 6 Hexes wide
        let scaleForBoard = (boardWidth) / 200 // 200 is the innate width of PlayerBoard SVG
        let angle = 60 - degGapBetweenPlayers * i
        let angleRad = angle / 180 * Math.PI
        let placementRadius = HEX_WIDTH * 9 * 2

        // The closest point to the board should be 5 hexes
        let maxX = HEX_WIDTH * 2 * 5.5
        let maxY = 5 * HEX_HEIGHT + 3 * HEX_SIDE + HEX_PEAK
        let boardTopLeftX = placementRadius * Math.cos(angleRad) - boardWidth
            / 2
        let boardTopLeftY = Math.min(
            maxY, Math.max(
                -maxY - boardWidth / 2,
                -placementRadius * Math.sin(angleRad) - boardWidth / 4
            ))

        playerBoards.push(<g
            transform={"translate(" + boardTopLeftX + " " + boardTopLeftY
                + ") scale(" + scaleForBoard + " " + scaleForBoard + ")"}>
          <PlayerBoard playerColor={PLAYER_COLORS[i]}
                       playerState={game.gameState.playerState[i]}
                       firstPasser={game.gameState.firstPasser}
                       isTurn={i === game.gameState.currentTurn
                           % game.players.length}
          />
        </g>)
      }
    }

    // Zooming and dragging
    let mouseDown = function (event) {
      console.log("Mouse down at ", event)
      if (event.button == 0) {
        // Left click
        setDragPosition({
          down: {x: event.pageX, y: event.pageY},
          downNative: convertScreenToSvg({
            x: event.pageX,
            y: event.pageY
          }, center, zoom, dimensions),
          center: {x: center.x, y: center.y}
        })
        event.preventDefault()
      }
    }
    let mouseUp = function (event) {
      console.log("Mouse up at ", event)
      setDragPosition(null)
    }
    let onMouseMove = function (event) {
      if (dragPosition != null) {

        let originalPtConverted = dragPosition.downNative
        let currentPtConverted = convertScreenToSvg({
          x: event.pageX,
          y: event.pageY
        }, dragPosition.center, zoom, dimensions)

        let diffX = originalPtConverted.x - currentPtConverted.x
        let diffY = originalPtConverted.y - currentPtConverted.y

        let bounds = 20 * HEX_HEIGHT;
        setCenter({
          x: Math.max(-bounds, Math.min(bounds, dragPosition.center.x + diffX)),
          y: Math.max(-bounds, Math.min(bounds, dragPosition.center.y + diffY))
        })
      }
    }
    let onWheel = function (event) {
      console.log("Wheel at", event)
      let oldZoom = zoom;
      let newZoom = Math.min(40 * HEX_WIDTH,
          Math.max(zoom + 2 * HEX_WIDTH * event.deltaY / 120, 6 * HEX_WIDTH))

      let pt = {
        x: event.pageX,
        y: event.pageY
      }

      let ptUnderOldZoom = convertScreenToSvg(pt, center, oldZoom, dimensions)
      let ptUnderNewZoom = convertScreenToSvg(pt, center, newZoom, dimensions)
      let diffX = ptUnderNewZoom.x - ptUnderOldZoom.x
      let diffY = ptUnderNewZoom.y - ptUnderOldZoom.y

      // You want the position under mouse to not move on the zoom action, so need to compute the delta
      // of that position and adjust center accordingly

      setZoom(newZoom)
      let bounds = 20 * HEX_HEIGHT;
      setCenter({
        x: Math.max(-bounds, Math.min(bounds, center.x - diffX)),
        y: Math.max(-bounds, Math.min(bounds, center.y - diffY))
      })

      event.preventDefault()
    }

    return (
        // Viewbox is top left top right coordinate, and width and height
        <svg onMouseMove={onMouseMove} onMouseDown={mouseDown}
             onMouseUp={mouseUp} onWheel={onWheel}
             className="Table" preserveAspectRatio="none"
             width={dimensions.width} height={dimensions.height}
             viewBox={topX + " " + topY + " " + viewBoxWidth + " "
                 + viewBoxHeight}>
          <Grid/>
          {hexes}
          {playerBoards}
        </svg>
    );
  }
}

function getTurnOrder(id, players) {
  for (let i = 0; i < players.length; i++) {
    if (id == players[i].id) {
      return i;
    }
  }

  throw "Illegal argument " + id + " for players " + players
}

function convertScreenToSvg(pt, center, zoom, windowDimensions) {
  let xP = pt.x / windowDimensions.width; // x percent
  let yP = pt.y / windowDimensions.height // y percent

  let aspectRatio = windowDimensions.width / windowDimensions.height
  let viewBoxWidth, viewBoxHeight;
  if (aspectRatio <= 1) {
    // We're taller than we are wide
    viewBoxWidth = zoom
    viewBoxHeight = viewBoxWidth / aspectRatio
  } else {
    // We're wider than we are tall
    viewBoxHeight = zoom
    viewBoxWidth = viewBoxHeight * aspectRatio
  }
  let topX = center.x - (viewBoxWidth / 2)
  let topY = center.y - (viewBoxHeight / 2)

  return {
    x: topX + xP * viewBoxWidth,
    y: topY + yP * viewBoxHeight
  }
}

function Grid() {
  let result = []
  let numToDraw = 8

  for (let q = -numToDraw; q <= numToDraw; q++) {
    for (let r = -numToDraw; r <= numToDraw; r++) {
      let centerX = HEX_SIZE * Math.sqrt(3) * q + HEX_SIZE * Math.sqrt(3) * r
          / 2
      let centerY = HEX_SIZE * 3.0 / 2 * r
      let center = {x: centerX, y: centerY}
      let distance = (Math.abs(q) + Math.abs(r) + Math.abs(q + r)) / 2
      let opacity = 1 / Math.pow(1 + distance, 1.7)

      for (let c = 0; c < 6; c++) {
        let p1 = hexCorner(center, HEX_SIZE, c)
        let p2 = hexCorner(center, HEX_SIZE, (c + 1) % 6)

        result.push(<line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke="black"
                          strokeWidth="0.5"
                          strokeOpacity={opacity}/>)
      }
    }
  }

  return result
}

/**
 * {
 *   "definition": {
 *     "ring": 2,
 *     "points": 3,
 *     "artifactKey": true,
 *     "populationSquares": {
 *       "GOLD": 1
 *     },
 *     "hasInitialDiscoveryTile": false,
 *     "warpPortal": false,
 *     "wormhole": [
 *       "E",
 *       "W"
 *     ],
 *     "initialAlienShips": []
 *   },
 *   "rotation": 0,
 *   "coordinate": "(2,-2,0)",
 *   "controller": {
 *     "id": 1,
 *     "human": false
 *   },
 *   "occupiedPopulation": {
 *     "GOLD": 1
 *   },
 *   "ships": [
 *     {
 *       "type": "PlayerShip",
 *       "owner": {
 *         "id": 1,
 *         "human": false
 *       },
 *       "shipType": "INTERCEPTOR"
 *     }
 *   ]
 * }
 *
 * @param hex
 * @returns {JSX.Element}
 * @constructor
 */

function PopSquares({type, num, occupied, c, ownerId, players}) {
  let res = []

  let occupiedNum = occupied
  if (!occupiedNum) {
    occupiedNum = 0
  }

  let advanced = type.endsWith("advanced")
  if (advanced) {
    type = type.substring(0, type.length - "/advanced".length)
  }

  let fill
  let height = POP_SQUARE_SIZE
  let gapY = HEX_SIZE / 15
  let xMargin = HEX_SIZE / 2
  let x = c.x - (HEX_SIZE - xMargin)
  let gap = (2 * HEX_SIZE - 2 * xMargin) / 3;
  if (type === "GOLD") {
    x += 0 * gap
    fill = GOLD_COLOR
  } else if (type === "SCIENCE") {
    x += 1 * gap
    fill = SCIENCE_COLOR
  } else if (type === "METAL") {
    x += 2 * gap
    fill = METAL_COLOR
  } else {
    throw "Unknown PopSquare type " + type
  }

  for (let i = 0; i < num; i++) {
    let occ = i < occupiedNum
    let topLeftX = x - height / 2;
    let topLeftY = c.y - INFLUENCE_DISC_RADIUS - height - i * (height + gapY)
    res.push(<rect stroke="none" fill={fill}
                   x={topLeftX} y={topLeftY} width={height}
                   height={height}
                   rx={height / 5}/>)
    if (occ) {
      let color = PLAYER_COLORS[getTurnOrder(ownerId, players)]
      let margin = 1 / 5.0 * height

      res.push(<rect stroke="none" fill={color}
                     x={topLeftX + margin} y={topLeftY + margin}
                     width={height - 2 * margin}
                     height={height - 2 * margin}
                     rx={height / 5}/>)
    }
    if (advanced) {
      res.push(<Asterisk radius={height / 3 * 2}
                         c={{x: topLeftX + height, y: topLeftY}}/>)
    }
  }

  return res
}

function Asterisk({c, radius}) {
  // 32 x 32 ?
  return <g
      transform={"translate(" + c.x + "," + c.y + ") scale(" + 1 / 32.0 * radius
          + ") translate(-16,-16) "}>
    <path fill="#642"
          d={"M 0,14.355469 2.2460938,7.421875 C 7.4218645,9.2448552 11.181626,10.82363 13.525391,12.158203 "
              +
              "12.906885,6.2663426 12.581365,2.2136123 12.548828,0 l 7.080078,0 c -0.09768,3.2227258 -0.472027,7.2591801 "
              +
              "-1.123047,12.109375 3.35284,-1.692646 7.193982,-3.2551444 11.523438,-4.6875 l 2.246094,6.933594 c -4.134146,1.367244"
              +
              " -8.186877,2.278702 -12.158204,2.734375 1.985652,1.725314 4.785129,4.801483 8.398438,9.228515 L 22.65625,30.46875"
              +
              " C 20.768205,27.89718 18.53839,24.397835 15.966797,19.970703 13.557926,24.560595 11.442043,28.059941"
              +
              " 9.6191406,30.46875 L 3.8574219,26.318359 C 7.6334528,21.663463 10.335273,18.587294 11.962891,17.089844 "
              +
              "7.763661,16.276098 3.7760348,15.364641 0,14.355469"}/>
  </g>
}

function Hex({hex, players}) {
  let c = deserializeCoordinates(hex.coordinate)

  let centerX = HEX_SIZE * Math.sqrt(3) * c.q + HEX_SIZE * Math.sqrt(3) * c.r
      / 2
  let centerY = HEX_SIZE * 3.0 / 2 * c.r
  let center = {x: centerX, y: centerY}

  let pts = [
    hexCorner(center, HEX_SIZE, 0),
    (hexCorner(center, HEX_SIZE, 1)),
    (hexCorner(center, HEX_SIZE, 2)),
    (hexCorner(center, HEX_SIZE, 3)),
    (hexCorner(center, HEX_SIZE, 4)),
    (hexCorner(center, HEX_SIZE, 5))
  ]

  let ptStr = pts.map(pt => pt.x + "," + pt.y).join(" ")

  let fillColor = "#BBB"

  // TODO: Draw ships, occupied population, unoccupied cubes, etc
  let res = []
  res.push(<polygon points={ptStr} fill={fillColor} strokeWidth="0.5"
                    stroke="#333"/>)

  if (hex.controller) {
    res.push(<circle r={INFLUENCE_DISC_RADIUS}
                     fill={PLAYER_COLORS[getTurnOrder(hex.controller.id,
                         players) % PLAYER_COLORS.length]}
                     cx={centerX}
                     cy={centerY}/>)
  }

  if (hex.definition.populationSquares) {
    for (let type in hex.definition.populationSquares) {
      let numOccupied = 0
      if (hex.occupiedPopulation && hex.occupiedPopulation[type]) {
        numOccupied = hex.occupiedPopulation[type]
      }

      let controller = null
      if (hex.controller) {
        controller = hex.controller.id
      }

      res.push(<PopSquares type={type}
                           num={hex.definition.populationSquares[type]}
                           occupied={numOccupied} c={center}
                           ownerId={controller} players={players}/>)
    }
  }

  if (hex.ships) {
    for (let i in hex.ships) {
      let s = hex.ships[i]
      if (s.shipType == "INTERCEPTOR") {
        let shipCenter = {
          x: center.x + Math.cos(degToRad(60 * 3 + 30)) * HEX_SIZE / 2,
          y: center.y - Math.sin(degToRad(60 * 3 + 30)) * HEX_SIZE / 2
        }
        res.push(<Interceptor c={shipCenter} width={HEX_SIDE / 3}/>)
      } else {
        console.log("Unknown ship type " + s.shipType)
      }
    }
  }

  for (let i in hex.definition.wormhole) {
    let d = hex.definition.wormhole[i]
    let idx = dirToIndex(d)
    let rotatedIdx = idx - hex.rotation

    let pt1 = pts[(rotatedIdx + 6) % 6]
    let pt2 = pts[(rotatedIdx + 7) % 6]

    let arc1Pt1 = linearCombination(pt1, pt2, 0.25)
    let arc1Pt2 = linearCombination(pt1, pt2, 0.75)
    let radius1 = dist(arc1Pt1, arc1Pt2) / 2
    let arc2Pt1 = linearCombination(pt1, pt2, 0.35)
    let arc2Pt2 = linearCombination(pt1, pt2, 0.65)
    let radius2 = dist(arc2Pt1, arc2Pt2) / 2

    let pathD = "M " + arc1Pt1.x + "," + arc1Pt1.y
        + " A " + radius1 + " " + radius1 + " 0 0 1 " + arc1Pt2.x + " "
        + arc1Pt2.y
        + " L " + arc2Pt2.x + " " + arc2Pt2.y
        + " A " + radius2 + " " + radius2 + " 0 0 0 " + arc2Pt1.x + " "
        + arc2Pt1.y
        + " Z"

    res.push(<path fill="#DDD" fillOpacity="0.7"
                   d={pathD}/>)
  }

  return res;
}

function degToRad(deg) {
  return deg / 180 * Math.PI
}

function dist(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function linearCombination(pt1, pt2, number) {
  return {
    x: pt2.x * number + pt1.x * (1 - number),
    y: pt2.y * number + pt1.y * (1 - number)
  };
}

/**
 * Corner 0 is bottom of E side, then it goes CCW
 *
 * @param center
 * @param size
 * @param i
 * @returns {{x: *, y: *}}
 */
function hexCorner(center, size, i) {
  var angle_deg = 60 * i - 30
  var angle_rad = Math.PI / 180 * angle_deg
  return {
    x: center.x + size * Math.cos(angle_rad),
    y: center.y - size * Math.sin(angle_rad)
  }
}

function dirToIndex(dir) {
  switch (dir) {
    case 'E':
      return 0
    case 'NE':
      return 1
    case 'NW':
      return 2
    case 'W':
      return 3
    case 'SW':
      return 4
    case 'SE':
      return 5
  }
}

/**
 * Returns {q: 1, r: 1} from "(q,r,s)"
 * @param str
 */
function deserializeCoordinates(str) {
  const regex = /\((-?\d+),(-?\d+),.*\)/
  let match = regex.exec(str)
  return {q: parseInt(match[1]), r: parseInt(match[2])}
}

function Interceptor({c, width}) {
  return <g
      transform={"translate(" + (c.x) + "," + (c.y) + ") scale(" + (width / 512)
          + ") translate(-256, -256)"}>
    <g>
      <path d="M135.96,358.4c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S141.08,358.4,135.96,358.4z"/>
      <path d="M135.96,392.533c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				C144.493,395.947,141.08,392.533,135.96,392.533z"/>
      <path d="M135.96,426.667c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S141.08,426.667,135.96,426.667z"/>
      <path d="M255.427,238.933c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S260.547,238.933,255.427,238.933z"/>
      <path d="M255.427,273.067c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S260.547,273.067,255.427,273.067z"/>
      <path d="M255.427,307.2c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S260.547,307.2,255.427,307.2z"/>
      <path d="M374.893,358.4c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S380.013,358.4,374.893,358.4z"/>
      <path d="M374.893,392.533c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				C383.427,395.947,380.013,392.533,374.893,392.533z"/>
      <path d="M374.893,426.667c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533
				S380.013,426.667,374.893,426.667z"/>
      <path d="M507.16,367.787l-0.853-1.707C381.72,171.52,303.213,46.933,296.387,33.28C281.027,0.853,260.547,0,255.427,0
				s-25.6,0.853-40.96,33.28C205.933,48.64,100.12,215.893,2.84,368.64c-2.56,3.413-3.413,6.827-3.413,11.093v65.707
				c0,5.973,2.56,11.947,7.68,16.213c4.267,3.413,8.533,5.12,13.653,5.12c1.707,0,2.56-0.853,4.267-0.853l76.8-15.36v10.24
				c0,9.387,7.68,17.067,17.067,17.067h8.533v25.6c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-25.6h8.533
				c9.387,0,17.067-7.68,17.067-17.067v-24.796l26.773-5.432l-0.32,6.335c-0.853,6.827,1.707,12.8,5.973,17.067
				s10.24,6.827,16.213,6.827h28.16v42.667c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V460.8h28.16
				c5.973,0,11.947-2.56,16.213-6.827s5.973-10.24,5.973-16.213l-0.428-8.484l26.881,6.233V460.8c0,9.387,7.68,17.067,17.067,17.067
				h8.533v25.6c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-25.6h8.533c9.387,0,17.067-7.68,17.067-17.067v-12.529
				l75.947,16.796c6.827,1.707,12.8,0,17.92-4.267c5.12-3.413,7.68-9.387,7.68-16.213V378.88
				C510.573,375.467,509.72,371.2,507.16,367.787z M135.576,290.153c2.306,0.048,4.438,0.436,6.391,1.099
				c0.193,0.073,0.392,0.134,0.582,0.214c0.017,0.006,0.033,0.013,0.05,0.02c6.113,2.603,10.428,8.679,10.428,15.714v17.067h-34.133
				v-15.36c0-10.064,7.421-17.639,15.776-18.708C134.97,290.176,135.272,290.16,135.576,290.153z M153.027,460.8h-34.133v-13.653
				V341.333h34.133v98.133V460.8z M269.08,110.933l11.947,11.947l2.56,47.787H263.96v-59.733H269.08z M246.893,170.667h-19.527
				l2.461-47.787l11.947-11.947h5.12V170.667z M297.24,442.027c-1.707,0.853-2.56,1.707-4.267,1.707H263.96v-68.267
				c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v68.267h-27.307c-1.707,0-3.413-0.853-4.267-1.707
				c-0.853-1.707-1.707-2.56-1.707-4.267l12.874-250.027h58.23l14.229,250.027C298.947,439.467,298.093,441.173,297.24,442.027z
				 M374.617,290.147c4.143,0.144,8.924,1.807,11.37,4.253c3.413,3.413,5.12,7.68,5.12,12.8l0.632,17.067h-33.912v-15.36
				c0-10.064,7.421-17.639,15.776-18.708C373.939,290.174,374.276,290.153,374.617,290.147z M357.827,460.8v-21.333v-98.133h34.133
				V460.8H357.827z M494.36,444.587c0,1.707-0.853,2.56-1.707,3.413c-0.853,0.853-1.707,0.853-3.413,0.853l-80.213-16.213V307.2
				c0-9.387-3.413-17.92-10.24-24.747s-16.213-10.24-25.6-9.387c-2.264,0.108-4.473,0.462-6.605,1.035
				c-14.79,3.737-25.821,17.19-25.821,33.099v1.707v109.227l-27.307-5.12l-15.36-296.96l-22.187-22.187h-40.96l-22.187,22.187
				L197.4,412.16l-27.307,5.973V307.2c0-9.387-3.413-17.92-10.24-24.747s-16.213-10.24-25.6-9.387
				c-2.264,0.108-4.473,0.462-6.605,1.035c-14.79,3.737-25.821,17.19-25.821,33.099v1.707V432.64l-80.213,16.213
				c-1.707,0-2.56,0-3.413-0.853s-1.707-1.707-1.707-3.413V378.88c0-0.853,0-0.853,0.853-2.56
				C77.933,282.453,218.733,60.587,228.973,40.96c11.947-23.04,23.893-23.893,24.747-23.893h0.64h1.067h0.853
				c0.853,0,13.653,0,25.6,23.893c9.387,19.627,151.04,240.64,210.773,334.507l0.853,0.853c0.853,0.853,0.853,1.707,0.853,2.56
				V444.587z"/>
    </g>
  </g>
}

export {Header, Table}
